/*------------------------------------*\
  Breakpoint
\*------------------------------------*/

.bp-breakpoint {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: var(--blueprint-breakpoint-border);
    border-right: var(--blueprint-breakpoint-border);
    border-bottom: var(--blueprint-breakpoint-border);
}