/*------------------------------------*\
  Grid
\*------------------------------------*/

.bp-container--fluid,
.bp-container {
    width: 100%;
    max-width: var(--container-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--gutter-space);
    padding-right: var(--gutter-space);
}

.bp-container--fluid {
    max-width: 100%;
}

.bp-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--gutter-space));
    margin-right: calc(-1 * var(--gutter-space));
}

.bp-col {
    padding-left: var(--gutter-space);
    padding-right: var(--gutter-space);
    width: 100%;
    max-width: 100%;

    @for $i from 1 through 24 {
        &-#{$i} {
            width: calc((100% / var(--number-of-columns)) * #{$i});
            padding-right: var(--gutter-space);
            padding-left: var(--gutter-space);
        }
    }
}

.bp-offset {
    @for $i from 1 through 24 {
        &-#{$i} {
            margin-left: calc((100% / var(--number-of-columns)) * #{$i});
        }
    }
}