.bp-skin-settings {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.bp-skin-settings__iframe {
    flex: 1 0;
}

.bp-skin-settings__form {
    position: relative;
    overflow: auto;

    --form-padding-horizontal: 30px;
    --form-padding-vertical: 0;
    --form-group-padding: 30px 0;

    &:before  {
        content: '';
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        height: 15px;
        top: 0;
        background: linear-gradient(to bottom, var(--blueprint-side-bar-background), transparent);
    }

    .ak-form__title {
        display: none;
    }

    .ak-form__group {
        border-bottom: 1px solid rgba(var(--color-2-rgb), .4);
    }
}

.bp-skin-settings__footer {
    position: relative;
    padding: 15px 0;

    &:after {
        content: '';
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        height: 15px;
        bottom: 100%;
        background: linear-gradient(to top, var(--blueprint-side-bar-background), transparent);
    }
}