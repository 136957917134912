/*------------------------------------*\
  Blueprints Variables
\*------------------------------------*/

:root {
    --blueprint-main-padding-horizontal: 28px;
    --blueprint-breakpoint-border:  1px solid rgba(var(--color-2-rgb), .25);

    // Side bar
    --blueprint-side-bar-width: 275px;
    --blueprint-side-bar-background : #d5deec;
    --blueprint-side-bar-padding: 85px 0 0;
    --blueprint-side-bar-content-padding: 0 30px;

    // Block type list
    --block-type-list-padding: 15px 30px;

    // Block
    --block-background: #FFF;
    --block-color: var(--color-text);
    --block-shadow: 5px 0 11px rgba(0, 0, 0, .1);
    --block-font-size: 1.25rem;
    --block-font-size-has-children: .875rem;
    --block-margin-vertical: 15px;
    --block-has-children-shadow: none;
    --block-has-children-color: rgba(var(--color-2), .7);
    --block-has-children-background: rgba(var(--color-2-rgb), .05); //#e4ebf5
    --block-border-radius: var(--border-radius);
    --block-header-has-children-padding: 15px 20px 0;
    --block-header-padding: 15px 20px 15px 20px;
    --block-describer-padding: 0px 20px 15px 20px;
    --block-guides-outline: 3px solid #7b8896;

    // Block preview
    --block-preview-border: 3px solid var(--color-1);
    --block-preview-border-radius: var(--border-radius);
    --block-preview-header-background: var(--color-1);
    --block-preview-header-color: #FFF;
    --block-preview-header-font-size: 1rem;

    // Grid
    --number-of-columns: 12;
    --gutter-space: 12px;
    --container-width: 1320px;

}