/*------------------------------------*\
  Block type list
\*------------------------------------*/

.bp-block-type-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, var(--blueprint-side-bar-background), transparent);
        height: 15px;
    }
}

.bp-block-type-list__content {
    position: relative;
    padding: var(--block-type-list-padding);
    margin: -20px 0;

    &:before {
        content: '';
        position: absolute;
        top: calc(100% - 20px);
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, var(--blueprint-side-bar-background), transparent);
        height: 15px;
    }
}

.bp-block-type-list__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -5px;

    overflow: auto;
    padding: var(--block-type-list-padding);
}

.bp-block-type {
    flex: 1 0 calc(50% - 10px);
    display: flex;
    margin: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    transition: .3s ease background;
    background-color: var(--block-background);
    border-radius: var(--block-border-radius);
    box-shadow: var(--block-shadow);

    &:hover,
    &:focus,
    &:active {
        background: rgba(0,0,0,0.2);
    }

    &--dragging {
        opacity: .5;
    }
}

.bp-block-type__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}