/*------------------------------------*\
  Preview
\*------------------------------------*/

.bp-preview {
    position: relative;

    &--show-guides .bp-block--preview:before {
        opacity: 1;
    }
}

.bp-preview__editor {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.bp-preview__row {
    display: flex;
    flex-wrap: wrap;
}

.bp-preview__container {
    margin: 0 auto;
}