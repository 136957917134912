@import "variables";

@import "partials/block";
@import "partials/dropzone";
@import "partials/resizable";
@import "partials/tabs";
@import "partials/dropdown";
@import "partials/block-type-list";
@import "partials/preview";
@import "partials/breakpoint";
@import "partials/grid";

@import "partials/breakpoint-dropdown";
@import "partials/blueprint-side-bar";
@import "partials/skin-settings";

.bp-blueprint {
    --cols: 12;
    --gutter-size: 15px;

    display: flex;
    flex-direction: column;
    padding: var(--blueprint-main-padding);
}

.bp-blueprint__main {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    padding-top: calc(var(--top-bar-height) + 40px);
    padding-right: calc(var(--blueprint-main-padding-horizontal) + calc(var(--blueprint-side-bar-width)));
    padding-left: var(--blueprint-main-padding-horizontal);
}

.bp-blueprint__editor {
    flex: 1 0;
    display: flex;
    flex-direction: column;
}

.bp-contianer {
    position: relative;
}