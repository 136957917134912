/*------------------------------------*\
  Dropdown
\*------------------------------------*/


.bp-dropdown {
    display: inline-block;
    position: relative;
}

.bp-dropdown__body {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    width: auto;
    min-width: 300px;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: #FFF;
    transform: translateY(40px);
    pointer-events: none;
    transition: .3s ease opacity,
                .3s ease transform;

    &--right {
        left: initial;
        right: 0;
    }

    &--visible {
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
        transform: initial;
    }
}