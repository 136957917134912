/*------------------------------------*\
  Blueprint side bar
\*------------------------------------*/

.bp-blueprint-side-bar {
    position: fixed;
    z-index: 1;
    top: calc(var(--header-height) + var(--top-bar-height));
    right: 0;
    bottom: 0;
    width: var(--blueprint-side-bar-width);
    display: flex;
    flex-direction: column;
    background: var(--blueprint-side-bar-background);
    padding: var(--blueprint-side-bar-padding);
}

.bp-blueprint-side-bar__content {
    padding: var(--blueprint-side-bar-content-padding);
}

.bp-blueprint-side-bar__link {
    position: relative;
    display: block;
    padding-left: 30px;
    text-decoration: none;
    margin-bottom: 20px;

    &:hover {
        text-decoration: underline;
    }
}

.bp-blueprint-side-bar__link-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 1.15rem;
}

//.bp-blueprint-side-bar__overflow {
//    //position: relative;
//
//    //&--before:before {
//    //    content: '';
//    //    position: absolute;
//    //    top: calc(100% - 20px);
//    //    left: 0;
//    //    right: 0;
//    //    background: linear-gradient(to bottom, var(--blueprint-side-bar-background), transparent);
//    //    height: 15px;
//    //}
//    //
//    //&--after:after {
//    //    content: '';
//    //    position: absolute;
//    //    bottom: 0;
//    //    left: 0;
//    //    right: 0;
//    //    background: linear-gradient(to top, var(--blueprint-side-bar-background), transparent);
//    //    height: 15px;
//    //}
//}