/*------------------------------------*\
  Resizable
\*------------------------------------*/

.resizable-wrapper {
    --resizable-handle-left-offset: 0;
    --resizable-handle-right-offset: 0;

    position: relative;
}

.resizable {
    position: relative;
    resize: none;
    height: 100%;
}

.resizable__handle {

    position: absolute;
    bottom: 0;
    height: 100%;
    width: 8px;
    cursor: col-resize;
    text-indent: -9999px;
    overflow: hidden;
    border: none;
    background: none;
    z-index: 4;

    &:hover {
        cursor: ew-resize;
    }

    &--left {
        left: var(--resizable-handle-left-offset);
    }

    &--right {
        right: var(--resizable-handle-right-offset);
    }

    &--disabled {
        pointer-events: none;
    }
}