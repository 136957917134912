/*------------------------------------*\
  Dropzone
\*------------------------------------*/

.bp-dropzone {
    width: 100%;
    padding: 0 var(--gutter-size);
    min-height: 50px;

    // on the root dropzone's the containers serve the padding
    &--root {
        padding: 0;
    }

    &--full {
        height: 100%;
    }

    &--preview {
        padding: 0;
    }

    &:not(.bp-dropzone--active) .bp-block:hover {
        cursor: grab;
    }

    &--active {
        cursor: grabbing !important;
    }
}

.bp-dropzone__main {
    position: relative;
}

.bp-dropzone__grid {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    pointer-events: none;
    background-blend-mode: multiply;
    padding-left: var(--gutter-size);
    padding-right: var(--gutter-size);
    overflow: hidden;

    > .bp-row {
        width: 100%;
        flex-wrap: wrap;
        flex: 1 1;
    }

    [class*="bp-col-"] {
        position: relative;
    }
}

.bp-dropzone__column {
    //position: relative;
    height: 100%;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: var(--gutter-size);
        background: rgba( 0, 0, 0, .1);
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }

    &--active-hover:after {
        border-left: 3px solid var(--color-info);
    }

    &--active:after {
        border-left: 3px solid var(--color-danger);
    }
}