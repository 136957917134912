/*------------------------------------*\
  Block
\*------------------------------------*/

.bp-block-wrapper {
    --block-offset-left: 0;
    --block-height: 40px;
    --block-width: 90%;

    --offset:  0;
    --colspan:  0;
    --col-width: calc((100%) / var(--colspan));
    --offset-size: calc((var(--col-width) * var(--offset)));

    &--offset {
        &:before {
            content: '';
            position: absolute;
            top: var(--block-margin-vertical);
            bottom: var(--block-margin-vertical);
            left: calc( -1 * (var(--offset-size) - var(--gutter-size)));
            width: var(--offset-size);
            background: #FFF;
            border-radius: var(--border-radius) 0 0 var(--border-radius);
            opacity: .3;
            box-shadow: 5px 0 11px rgba(0, 0, 0, .25);
        }
    }

    &--full-width.resizable-wrapper {
        --resizable-handle-left-offset: calc(var(--gutter-size) * -1);
        --resizable-handle-right-offset: calc(var(--gutter-size) * -1);
    }

    &--preview {
        margin-left: var(--block-offset-left);
        min-height: var(--block-height);
        width: var(--block-width);
        transition: var(--animation-duration) ease min-height,
                    var(--animation-duration) ease margin-left,
                    var(--animation-duration) ease padding;

        &.bp-block-wrapper--offset:before {
            // @todo checken of we in preview de offset moeten tonen
            display: none;
            left: calc( -1 * (var(--offset-size)));
            transition: var(--animation-duration) ease  width,
            var(--animation-duration) ease left;
        }

        &:hover {
            &.bp-block-wrapper--offset:before {
                width: calc(var(--offset-size) - 40px);
            }
        }

        &:hover > .resizable > .bp-block--preview:not(.bp-block--no-hover) {
            &:before {
                opacity: 1;
            }

            > .bp-block__header {
                opacity: 1;
                pointer-events: initial;
            }

            > .bp-block__header > .bp-block__actions {
                opacity: 1;
                pointer-events: initial;
            }

            &.bp-block--has-children {
                margin-top: -60px;
                margin-left: -60px;
                margin-right: -60px;
                margin-bottom: -60px;
                height: calc(100% + 120px);
                width: calc(100% + 120px);
                padding: 60px;
            }
        }

        &:hover  > .resizable > .bp-block__actions {
            opacity: 1;
            pointer-events: initial;
        }
    }
}

.bp-block {
    position: relative;
    display: block;
    margin-top: var(--block-margin-vertical);
    margin-bottom: var(--block-margin-vertical);
    transition: var(--animation-duration) ease opacity,
                var(--animation-duration-fast) ease outline,
                var(--animation-duration) ease border;
    max-height: 100%;
    min-height: 80px;
    color: var(--block-color);
    background-color: var(--block-background);
    border-radius: var(--block-border-radius);
    box-shadow: var(--block-shadow);


    &--has-children {
        color: var(--block-has-children-color);
        background-color: var(--block-has-children-background);
        box-shadow: var(--block-has-children-shadow);
    }

    &--dragging {
        opacity: .5;
    }

    &--full-width {
        margin-left: calc(var(--gutter-size) * -1);
        margin-right: calc(var(--gutter-size) * -1);
    }

    &--guides {
        outline: var(--block-guides-outline);
    }

    &--hidden {
        opacity: .6;

        .bp-dropzone .bp-block__actions {
            display: none;
        }
    }
}

.bp-block--preview {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: initial;
    margin: 0;
    background: none;
    box-shadow: none;
    border: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: var(--block-preview-border);
        border-radius: 0 var(--block-preview-border-radius) var(--block-preview-border-radius) var(--block-preview-border-radius);
        opacity: 0;
        transition: var(--animation-duration) ease opacity;
    }

    &.bp-block--has-children {
        transition: var(--animation-duration) ease margin,
        var(--animation-duration) ease height,
        var(--animation-duration) ease width,
        var(--animation-duration) ease padding;
    }

    &.bp-block--dragging,
    &.bp-block--active {
        &:before {
            opacity: 1;
        }

        > .bp-block__header {
            opacity: 1;
            pointer-events: initial;
        }

        &.bp-block--has-children {
            margin-top: -40px;
            margin-left: -40px;
            margin-right: -40px;
            margin-bottom: -40px;
            height: calc(100% + 80px);
            width: calc(100% + 80px);
            padding: 40px;
        }
    }

    &.bp-block--dragging {
        .bp-block__header {
            // @todo something
        }
    }
}

.bp-block__header {
    --title-font-size: var(--block-font-size);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--block-header-padding);

    &--preview {
        --title-font-size: var(--block-preview-header-font-size);

        position: absolute;
        top: 1px;
        left: 0;
        background: var(--block-preview-header-background);
        color: var(--block-preview-header-color);
        opacity: 0;
        pointer-events: none;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 1.2rem;
        border-radius: var(--block-preview-border-radius) var(--block-preview-border-radius) 0 0;
        transform: translateY(-100%);
    }

    &--has-children {
        --title-font-size: var(--block-font-size-has-children);

        text-transform: none;
        font-weight: bold;
        padding: var(--block-header-has-children-padding);
    }
}

.bp-block__header-title {
    font-size: var(--title-font-size);
    line-height: 1;
    text-transform: uppercase;
    word-break: break-word;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.bp-block__header-input {
    line-height: 1;
    text-transform: uppercase;
    padding: 5px;

    &--has-children {
        font-size: var(--block-font-size-has-children);
    }
}

.bp-block__actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    font-size: 1rem;
    border-radius: var(--border-radius);

    &--background {
        background: rgba(255,255,255, .7);
    }

    &--preview {
        position: static;
        margin-left: 5px;
        opacity: 0;
        pointer-events: none;
    }
}

.bp-block__describer {
    padding: var(--block-describer-padding);
}

.bp-block__action-icon {
    --material-icon-optical-size: 30;
    --material-icon-weight: 900;
    font-size: 1.3rem;
}

.v-application a.bp-block__delete,
.bp-block__delete {
    line-height: 1;
    text-decoration: none;
    transition: var(--animation-duration) ease;
    font-size: 1rem;

    &:hover {
        opacity: .5;
    }
}

.v-application a.bp-block__options,
.bp-block__options {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    font-size: 1rem;
    text-decoration: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background: white;
        opacity: 0;
        transition: .27s ease opacity;
    }

    &:hover {
        &:before {
            opacity: .2;
        }
    }
}

.bp-block__offset-remove {
    position: absolute;
    top: 5px;
    right: 5px;
}