/*------------------------------------*\
  Tabs
\*------------------------------------*/

.bp-tabs {
    flex: 0 1 100%;
}

.bp-tab {
    height: 100%;
    background: #f7f7f7;
    padding: 15px;
}

.bp-tab-navigation {
    background: var(--color-bg);
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.v-application a.bp-tab-navigation__item,
.bp-tab-navigation__item {
    display: inline-flex;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    text-decoration: none;
    color: var(--color-text);

    &:hover,
    &--active {
        background: #f7f7f7;
    }
}