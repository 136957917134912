/*------------------------------------*\
  Breakpoint dropdown
\*------------------------------------*/

.bp-breakpoint-dropdown {
    --popover-menu-item-padding: 15px 20px;

    .ak-form__checkmark {
        top: 0;
    }
}